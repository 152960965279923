<script>
import { mapState } from 'pinia'
import { useMultilingualStore } from '../../store/MultilingualStore'
import SocialNetworks from './SocialNetworks.vue'
export default {
  name: 'MenuMobile',
  components: { SocialNetworks },
  emits: ['routeClicked'],
  computed: {
    ...mapState(useMultilingualStore, ['menuMobile', 'lang', 'options']),
  },
}
</script>

<template>
  <nav id="menu" style="margin-right: 0px; margin-left: -400px; opacity: 1; top: 100px; height: 943px;">
    <div id="menu_content">
      <div class="content_width_wrapper_menu">
        <ul id="menu-menu-mobile" class="menu">
          <li v-for="item in menuMobile.menu" :key="item.slug" class="menu-item menu-item-type-post_type">
            <router-link
              :to="{
                name: 'Principale',
                params: { lang, page: item.slug },
              }"
              @click="$emit('routeClicked')"
            >
              {{ item.title }}
            </router-link>
          </li>
        </ul>
        <p id="burger_rs_accroche">
          {{ options.accroche_menu_mobile_suiveznous }}
        </p>
        <SocialNetworks />
      </div>
    </div>
  </nav>
</template>

<style>

</style>
