<script>
// const regexText = /[^a-zA-ZÀ-ÿ\-']/g // used to find special characters
// const regexEmail = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/ // used to make sure of email string

import { mapActions, mapState } from 'pinia'
import { useMultilingualStore } from '@/store/MultilingualStore'
import { postMailViaContactFormApi } from '@/utils/postMailViaContactFormApi'

export default {
  name: 'ContactForm',
  data() {
    return {
      errors: [],
      formID: 15622,
      showError: false,
      showSuccess: false,
      privacyPolivyApproved: false,
      errorPrivacyPolicy: false,
      forbiddenFieldChanged: false,
      showErrorCaptcha: false,
      showCaptcha: false,
    }
  },
  computed: {
    ...mapState(useMultilingualStore, ['captchaAnswer', 'contactFormData', 'contactFormFields', 'website', 'options', 'protocol']),
  },

  created() {
    this.getContactFormFields(this.formID)
  },
  updated() {
    if (this.$refs.inputs !== undefined) {
      this.showCaptcha = true
    }
  },
  methods: {
    ...mapActions(useMultilingualStore, ['fillCaptcha', 'getContactFormFields', 'emptyContactFormFields', 'fillContactFormData']),
    handleClickPrivacyPolice() {
      this.privacyPolivyApproved = !this.privacyPolivyApproved
      if (this.privacyPolivyApproved && this.errorPrivacyPolicy) {
        this.errorPrivacyPolicy = false
      }
    },
    pattern(val) {
      if (val === 'your-email') {
        return '[a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+.[a-zA-Z.]{2,15}'
      }
      return null
    },
    typeInput(val) {
      if (val === 'your-email') {
        return 'email'
      }
      return 'text'
    },
    handleBlankFields() {
      const inputs = this.$refs.form.querySelectorAll('input')
      inputs.forEach((input) => {
        if (input.value === '') {
          input.classList.add('blank-field')
          input.setAttribute('placeholder', this.options.empty_input_error_message)
        }
      })
      const textarea = this.$refs.form.querySelector('textarea')
      if (textarea.value === '') {
        textarea.classList.add('blank-field')
      }
    },
    handleBlur(e) {
      if (e.target.value === '') {
        e.target.classList.add('blank-field')
        e.target.setAttribute('placeholder', this.options.empty_input_error_message)
      }
    },
    checkForm(e) {
      e.preventDefault()
      if (this.privacyPolivyApproved && !this.forbiddenFieldChanged && this.captchaAnswer === '4') {
        let entryNotCompleted = false
        Object.entries(this.contactFormData).forEach((input) => {
          if (input[1] === '') {
            entryNotCompleted = true
          }
        })
        if (!entryNotCompleted) {
          const route = `${this.protocol + this.website}/wp-json/contact-form-7/v1/contact-forms/${this.formID}/feedback`
          postMailViaContactFormApi(route, 'post', this.contactFormData).then((value) => {
            // console.log(value.status)
            switch (value.status) {
              case 'validation_failed':
                this.showError = true
                this.handleBlankFields()
                break
              case 'mail_sent':
                this.showError = false
                this.showSuccess = true
                this.emptyContactFormFields()
                break
              default:
                break
            }
          })
        }
        else {
          this.handleBlankFields()
        }
      }
      else {
        if (this.captchaAnswer !== '4') {
          this.showErrorCaptcha = true
        }
        if (!this.privacyPolivyApproved) {
          this.errorPrivacyPolicy = true
        }
      }
    },
    fieldLabel: (label, options) => {
      switch (label) {
        case 'your-firstname':
          label = options.firstname
          break
        case 'your-name':
          label = options.surname
          break
        case 'your-email':
          label = options.email_address
          break
        case 'your-subject':
          label = options.subject
          break
        case 'your-message':
          label = options.message
          break
        default:
          label = 'field-name'
          break
      }
      return label
    },
  },
}
</script>

<template>
  <br>
  <br>
  <br>
  <div v-if="showSuccess" class="successMessage">
    {{ options.success_message }}
  </div>

  <form
    ref="form"
    class="form"
    action="" method="post" @submit="checkForm"
  >
    <p v-for="field in contactFormFields" ref="inputs" :key="field.name" :class="field.name">
      <label for="name">{{ fieldLabel(field.label, options) }}<span style="color:#0071BA">&nbsp;*</span></label>
      <input
        v-if="field.name !== 'your-message'"
        :id="field.name"
        :type="typeInput(field.name)" :name="field.name"
        :pattern="pattern(field.name)"
        @keyup="(e) => {
          if (e.target.classList.contains('blank-field')) {
            e.target.classList.remove('blank-field')
          }
          let key = e.target.name
          let dataForm = {}
          dataForm[key] = e.target.value
          fillContactFormData(dataForm)
        }"
        @blur="handleBlur"
      >
      <textarea
        v-else
        :id="field.name"
        type="text" :name="field.name" @keyup="(e) => {
          if (e.target.classList.contains('blank-field')) {
            e.target.classList.remove('blank-field')
          }
          let key = e.target.name
          let dataForm = {}
          dataForm[key] = e.target.value
          fillContactFormData(dataForm)
        }"

        @blur="handleBlur"
      />
    </p>
    <p v-if="!showSuccess && showCaptcha" class="captcha">
      <label for="captcha">2 + 2 = </label>
      <input
        id="captcha"
        ref="captcha" :class="showErrorCaptcha ? 'error-captcha' : ''" name="captcha" type="text" @change="(e) => {
          fillCaptcha(e.target.value)
        }"
      >
    </p>

    <div v-if="showError" class="errorMessage">
      {{ options.error_message }}
    </div>
    <div v-if="!showSuccess && showCaptcha">
      <input type="checkbox" class="checkboxPrivacy" @click="handleClickPrivacyPolice"><span :class="errorPrivacyPolicy ? 'error-privacy-policy checkboxPrivacyText' : 'checkboxPrivacyText'">{{ options.privacy_policy_and_legal_notice_button_text }}</span>
      <div class="privacyPolicyText">
        {{ options.privacy_policy_and_legal_notice_text }}
      </div>
      <input type="text" class="forbiddenField" @change="forbiddenFieldChanged = true"> <!-- Champ pour les robots -->
      <input
        type="submit" class="submit" :value="options.submit_button_text"
      >
    </div>
  </form>
</template>

<style>
.forbiddenField {
  visibility: hidden
}
.error-privacy-policy {
  color: red;
  font-weight: 600;
}
.blank-field , .error-captcha{
  background-color: rgb(202, 156, 156);
}
.privacyPolicyText, .checkboxPrivacyText, .checkboxPrivacy {
  margin-top: 1rem;
}
.checkboxPrivacyTex {
  margin-left: 1rem;
}

.errorMessage, .successMessage {
  width: 100%;
  margin: auto;
  text-align: center;
}
.errorMessage {
  color: red;
  font-weight: 600;
}
.form {
/* background-color: rgb(228, 179, 179); */
width: 70%;
margin: auto;
display: flex;
flex-wrap: wrap;
}
.form input {
   /* background-color: red */
}
.form p {
  width: 50%;
}

.form p {
  display: flex;
  flex-direction: column;
}

.form .your-subject, .form .your-message {
  width: 100%;
}

.form .your-message input {
  height: 5rem;
}

.form [name=your-firstname], .form [name=your-email], .form [name=captcha] {
  margin-right: 1rem;
}

.form [name=your-firstname], .form [name=your-email], .form [name=your-name], .form [name=your-subject], .form [name=captcha] {
  height: 50px;
  font-size: 16px;
}

.form [name=your-firstname], .form [name=your-email], .form [name=your-name], .form [name=your-subject], .form textarea, .form [name=captcha] {
 border-color: #0071BA !important;
}

.form input, .form textarea {
  border-radius: 5px !important;
  border: 2px solid;
}

.form textarea {
  min-height: 150px;
}

.form [type=submit] {
  background-color: #1E3288;
  color: white;
  border-radius: 25px !important;
  padding: 0.5rem 1rem 0.5rem 1rem;
  margin: auto;
  margin-top: 1rem;
  display: flex;
}

.your-name, .your-firstname, .your-email, .your-subject, .your-message, .captcha {
  margin-top: 1rem;
}
</style>
