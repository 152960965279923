export async function postMailViaContactFormApi(route, method, data) {
  const formData = new FormData()

  for (const name in data) {
    formData.append(name, data[name])
  }

  const response = await fetch(route, {
    method,
    contentType: 'multipart/form-data',
    body: formData,
  })

  const theResponse = response.json()

  return theResponse
}
