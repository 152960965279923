<script>
import { mapState } from 'pinia'
import { useMultilingualStore } from '../../store/MultilingualStore'
import SocialNetworks from './SocialNetworks.vue'
export default {
  name: 'PreFooter',
  components: { SocialNetworks },

  computed: {
    ...mapState(useMultilingualStore, ['options', 'menuFooter', 'lang']),
  },
}
</script>

<template>
  <aside id="aside_prefooter" class="padding_section">
    <div id="aside_prefooter_wrapper" class="content_width">
      <div id="aside_prefooter_col_logo" class="aside_prefooter_col">
        <div>
          <figure id="logo_footer" class="width_logo_70">
            <img :src="options.importer_le_logo_footer" alt="Logo Candriam">
          </figure>
          <p id="aside_prefooter_baseline">
            {{ options.accroche_sous_le_logo_footer }}
          </p>
        </div>
      </div>
      <div class="pre-footer-right">
        <span id="menu-footer">
          <router-link
            v-for="item in menuFooter.menu" :key="item.slug" :to="{
              name: 'Principale',
              params: { lang, page: item.slug },
            }"
          >{{ item.title }}</router-link>
        </span>
        <SocialNetworks />
      </div>
    </div>
    <div class="remove_margin" />
  </aside>
</template>

<style>
@media (max-width: 1024px) {
#aside_prefooter_col_logo {
  width: 100%;
}
#aside_prefooter_col_logo #logo_footer {
  width: 50%;
}
#aside_prefooter_wrapper #aside_prefooter_baseline {
  white-space: wrap !important;
}
#aside_prefooter_col_logo {
  margin-left: 0 !important;
}
.pre-footer-right #menu-footer {
  display: flex;
  flex-direction: column;
}
.pre-footer-right #menu-footer a {
  white-space: wrap;
}
}
</style>
