<script>
import { mapState } from 'pinia'
import { useMultilingualStore } from '../../store/MultilingualStore'
export default {
  name: 'MainMenu',
  computed: {
    ...mapState(useMultilingualStore, ['menu', 'lang']),
  },

}
</script>

<template>
  <nav id="menu_header" ref="mainMenu" class="content_hide_menu content_hide_menu_search">
    <div id="menu_header_wrapper">
      <ul class="menu">
        <li v-for="item in menu.menu" :key="item.slug" class="menu-item menu-item-type-post_type">
          <router-link
            :to="{
              name: 'Principale',
              params: { lang, page: item.slug },
            }"
          >
            {{ item.title }}
          </router-link>
        </li>
      </ul>
    </div>
  </nav>
  <!-- <router-view /> -->
</template>

<style>
</style>
