<!-- eslint-disable vue/v-on-event-hyphenation -->
<script>
import { mapActions, mapState } from 'pinia'
import { useMultilingualStore } from '../../store/MultilingualStore'

// svg
import SearchIcon from '@/components/svg/SearchIcon.vue'
import SearchiconClose from '@/components/svg/SearchiconClose.vue'

// buttons
import SelectLang from '@/components/buttons/SelectLang.vue'
import ButtonLogin from '@/components/buttons/ButtonLogin.vue'
import ContactButton from '@/components/buttons/ContactButton.vue'

// template-part
import MainMenu from '@/components/template-part/MainMenu.vue'
import MenuMobile from '@/components/template-part/MenuMobile.vue'

export default {
  name: 'HeaderPart',
  components: {
    ButtonLogin,
    MainMenu,
    SearchIcon,
    SearchiconClose,
    SelectLang,
    ContactButton,
    MenuMobile,

  },
  emits: ['openCloseSearchBox'],
  data() {
    return {
      isActive: false,
      hideMenu: 'hideMenu',
      clientWidth: window.innerWidth,
      showMainMenu: true,
    }
  },
  computed: {
    ...mapState(useMultilingualStore, ['options', 'menu', 'lang', 'options', 'currentWebsite', 'searchBoxOpened']),
    open() {
      return this.isActive ? 'open' : ''
    },

  },
  watch: {

    clientWidth(val) {
      if (this.lang === 'ja') {
        this.showMainMenu = val > 1330
      }
      else {
        this.showMainMenu = val > 1200
      }
    },
  },

  created() {
    window.addEventListener('resize', () => {
      this.clientWidth = window.innerWidth
    })
  },
  methods: {
    ...mapActions(useMultilingualStore, ['updateSearchBoxState']),
    checkWindowSizeOnMounted() {
      this.showMainMenu = window.innerWidth > 1200
    },
  },
}
</script>

<template>
  <header id="header">
    <div class="content_width_menu">
      <div class="brandig_mobile">
        <a :href="currentWebsite">
          <img :src="options.favicon">
        </a>
      </div>
      <div id="branding" ref="branding">
        <div id="content_header" class="content_large">
          <a :href="currentWebsite" title=" Retour à la page d'accueil">
            <figure id="figure-logo-header">
              <img
                id="logo_header_img" :src="options.importer_le_logo" alt="Logo Candriam"
                class="height70 logo_header_img_normal"
              >
            </figure>
          </a>
        </div>
      </div>
      <div id="content_nav_header" class="content_nav_header_noburger">
        <div class="buttons-menu-wrapper">
          <MainMenu v-if="showMainMenu" @vnodeMounted="checkWindowSizeOnMounted" />
          <ButtonLogin />
          <ContactButton />
          <div
            id="search_trigger"
            ref="searchTrigger" class="content_hide_menu" @click="() => {
              updateSearchBoxState() // cet événement gère l'ouverture et la fermeture de la searchBox, qui est gérée dans le composant parent
            }"
          >
            <SearchIcon
              v-if="!searchBoxOpened"
            />
            <SearchiconClose v-if="searchBoxOpened" />
          </div>
          <SelectLang />
          <nav
            id="menu_mobile_trigger"
            ref="menuMobileButton" tabindex="0" class="menu_mobile_trigger_noburger" @click="() => {
              hideMenu = 'fadeOut'
              isActive = !isActive;
            }"
          >
            <div id="burger" :class="open">
              <span />
              <span />
              <span />
              <span />
            </div>
          </nav>
        </div>
      </div>
    </div>
    <MenuMobile
      :class="[isActive ? 'showMenu' : hideMenu]"
      @routeClicked="() => {
        hideMenu = 'fadeOut'
        isActive = false
      }"
    />
  </header>
  <div id="menu_mask" :class="[isActive ? 'showMenu' : hideMenu]" style="top: 0px;" />
</template>

<style>
@media (max-width: 420px) {
  .branding_mobile {
    display: none !important;
  }
}
.small-window {
  display: none;
}
.large-window {
  display: block;
}
.hideMenu {
  display: none;
}
.showMenu {
  display: block !important;
}
.fadeOut {
  animation: fadeOut ease 0.2s;
  animation-fill-mode: forwards;
}
@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    visibility: hidden;
  }
}
</style>
